@import './reset';
@import './normalize';
@import './mixins';
@import './variables';

:root {
  @include defineBaseColorVariables($colors);
  @include defineTintColorVariables($colors);
  @include defineShadeColorVariables($colors);
  @include defineZIndexVariables();
}

@include defineCustomFontFamily('roboto', 800);

:root {
  --body-background-color: #ECECEC;
  --max-element-width: 2000px;
  --max-content-width: 700px;
  --content-margin: 25px;
  --base-font-size: 14px;
  --base-border-radius: 8px;
  --background-base-color: white;
  --navbar-height: 50px;
}

body {
  font-family: var(--font-family);
  background-color: var(--body-background-color);
  color: var(--text-color);
  font-size: var(--base-font-size);
}

a {
  color: var(--link-color);

  &:hover {
    text-decoration: underline;
  }
}

h1,
h2,
h3,
h4 {
  font-weight: var(--bold-font-weight);
}
